.main_table_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 0 100px;
  box-sizing: border-box;

  @include maxIpad {
    padding: 0 50px;
  }

  @include phone {
    padding: 0 32px;
  }

  @include minPhone {
    padding: 0;
    gap: 0;
  }

  .main_tab_home_page {
    @include phone {
      padding: 12px 12px 0;
      box-sizing: border-box;
      width: 100%;
      background-color: $dark_palette_2;
    }
  }

  .main_table_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 32px;
    background-color: $dark_palette_2;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;

    @include phone {
      padding: 12px;
      border-radius: 0;
    }

    .table_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;
      gap: 30px;

      @include phone {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .currency_header_selector {
        width: 350px;

        @include maxIpad {
          width: 250px;
        }

        @include phone {
          width: 100%;
        }
      }
    }

    .home_table_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;
    }

    .transactions_table_header {
      justify-content: space-between;
    }

    .table_body {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;

      .table_container_desktop {
        width: 100%;
        box-sizing: border-box;
        display: block;

        @include smallDesktop {
          display: none;
        }

        .table {
          width: 100%;
          box-sizing: border-box;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
          border-bottom: none;

          tr {
            width: 100%;
            box-sizing: border-box;

            &:first-child {
              th {
                &:last-child {
                  border-top-right-radius: 3px;
                }

                &:first-child {
                  border-top-left-radius: 3px;
                }
              }
            }
          }

          .header_element {
            padding: 10px 16px;
            color: $white;
            box-sizing: border-box;
            background-color: $dark_palette_3;
            text-align: start;
            font-family: mediumFont;
            font-weight: 500;
            width: calc(100% / 6);
            white-space: nowrap;

            @include minDesktop {
              max-width: 200px;
            }
          }

          .table_body_element {
            padding: 10px 16px;
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
            background-color: $dark_palette_2;
            width: calc(100% / 6);
            box-sizing: border-box;

            @include minDesktop {
              max-width: 200px;
            }

            .merchant_row {
              display: flex;
              flex-direction: row;
              gap: 16px;
              align-items: center;

              .merchant_rate {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;

                .star_icon {
                  width: 16px;
                  height: 16px;
                }
              }

              .merchant_name {
                white-space: nowrap;
                max-width: 100px;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .long_merchant_name {
                max-width: 180px;
              }
            }

            .location_name {
              white-space: nowrap;
              max-width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
            }

            .currency_row {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              .currency_title_field {
                display: flex;
                align-items: center;
                gap: 6px;

                .currency_line {
                  width: 1px;
                  height: 12px;
                  background-color: $dark_palette_6;
                }
              }

              .currency_icon {
                width: 24px;
                height: 24px;
              }
            }

            .price_row {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              .amd_currency_icon {
                width: 16px;
                height: 16px;
              }
            }

            .banks_row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;

              .banks_names_section {
                width: 90%;
                white-space: nowrap;
                display: flex;
                gap: 4px;

                .bank_name_ellipsis {
                  width: 30px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: block;
                  white-space: nowrap;
                }

                .banks_name_first_ellipsis {
                  max-width: 150px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: block;
                  white-space: nowrap;
                }
              }

              .hidden_banks {
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;

                .arrow_icon {
                  width: 16px;
                  height: 16px;
                  transform: rotate(0);
                  transition: all 0.3s;
                }

                .rotate_icon {
                  transform: rotate(-180deg);
                  transition: all 0.3s;
                }
              }

              .banks_dropdown {
                position: absolute;
                top: 40px;
                background-color: $dark_palette_1;
                border-radius: 8px;
                padding: 8px;
                display: flex;
                flex-direction: column;
                z-index: 10;
                min-width: 163px;
                max-height: 254px;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }

                .bank_dropdown_element {
                  padding: 6px 8px;
                }
              }
            }

            .view_details {
              background-color: transparent;
              border: none;
              color: $primary_green;
              font-size: 14px;
              line-height: 22px;
              text-decoration: underline;
              cursor: pointer;
              white-space: nowrap;
            }

            .disable_view_button {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
      }

      .table_pagination {
        padding: 12px 32px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        background-color: $dark_palette_3;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border: 1px solid #20212c;
        border-top: none;

        @include phone {
          padding: 12px;
        }
      }
    }
  }
}

.padding_0 {
  padding: 0;
}

.table_actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @include phone {
    width: 100%;
    box-sizing: border-box;
  }
}

.custom_tooltip {
  background-color: $dark_palette_1 !important;
  color: $placeholder_color !important;
  max-width: 250px;
  text-align: center;
}
