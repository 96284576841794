.for_merchants_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 104px 24px 0;

  @include phone {
    padding-top: 96px;
  }

  .merchant_form_container {
    justify-content: center !important;
    min-height: unset !important;
    padding-bottom: 0;
    background: none !important;

    @include phone {
      padding-top: 0;
    }

    .sign_up_form {
      width: auto;
      padding-top: 100px;
      padding-bottom: 100px;

      @include phone {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      .form_title {
        font-size: 46px;
        line-height: 55px;
        text-align: center;

        @include phone {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  .for_merchants_info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;

    .info_description_content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .info_description {
        width: 633px;
        max-width: 100%;
        text-align: center;
        line-height: 24px;
        color: $placeholder_color;

        @include phone {
          width: 100%;
        }
      }
    }
  }
}
