.create_trade_cards {
  width: 100%;
  box-sizing: border-box;

  .cards_section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .cards_section_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      align-items: center;

      .my_cards {
        color: $placeholder_color;
      }

      .change_card_button {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: $primary_green;
          transition: all 0.3s;
        }
      }
    }

    .card_empty_section_container {
      display: flex;
      flex-direction: column;
    }

    .cards_empty_section {
      background-color: $dark_palette_3;
      padding: 10px 16px;
      border-radius: 8px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;

      .trade_no_card {
        width: 24px;
        height: 24px;
      }

      .no_card_added {
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
      }

      .card_add_button {
        border: none;
        text-decoration: underline;
        color: $primary_green;
        background-color: transparent;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
      }
    }

    .cards_empty_section_error {
      outline: 1px solid $system_red;
    }

    .card_add_form {
      background-color: $dark_palette_2;
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      padding: 8px;
      gap: 16px;
      display: flex;
      flex-direction: column;

      .card_form_inputs {
        width: 100%;
        box-sizing: border-box;
        padding: 8px;
        gap: 20px;
        display: flex;
        flex-direction: column;
      }
    }

    .error_cards_form {
      outline: 1px solid $system_red;
    }
  }

  .trade_cards_grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $dark_palette_2;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
