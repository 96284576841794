.chat_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 4px;

  .mobile_padding {
    @include phone {
      padding: 16px !important;
    }
  }

  .chat_information {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
    background-color: $dark_palette_3;
    padding: 16px;

    @include phone {
      padding: 0;
    }

    .chat_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      box-sizing: border-box;

      @include ipad {
        flex-direction: column;
        align-items: flex-start;
      }

      .right_side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .back_icon {
          width: 24px;
          height: 24px;
          transform: rotate(90deg);
        }

        .user_info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;

          .user_rating {
            display: flex;
            align-items: center;
            gap: 4px;

            .star_icon {
              width: 16px;
              height: 16px;
            }

            .rating {
              font-size: 20px;
              font-family: mediumFont;
              color: $white;
            }
          }

          .user_name_section {
            .user_name {
              color: $white;
              font-size: 20px;
              font-family: mediumFont;

              @include phone {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }

      .left_side {
        display: flex;
        align-items: center;
        gap: 12px;

        .trade_info {
          .trade_id {
            color: $placeholder_color;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .chat_cards_section {
          display: flex;
          align-items: center;
          gap: 6px;

          .card_icon {
            width: 38px;
            height: 17px;
          }

          .card_number {
            font-size: 14px;
            color: $placeholder_color;
            line-height: 22px;
          }

          .copy_icon {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .trade_id_section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .trade_info {
        font-size: 14px;
        color: $placeholder_color;
        line-height: 22px;
      }
    }

    .chat_line {
      width: 100%;
      box-sizing: border-box;
      height: 1px;
      background-color: $dark_palette_5;
    }

    .about_trade {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include minPhone {
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
      }

      .about_item {
        display: flex;
        flex-direction: column;

        @include minPhone {
          flex-direction: row;
        }

        .item_title {
          font-size: 14px;
          color: $placeholder_color;
          line-height: 22px;

          @include minPhone {
            min-width: 140px;
          }
        }

        .item_value {
          font-size: 16px;
          color: $placeholder_color;
          font-family: mediumFont;
          line-height: 24px;

          .price_rate_section {
            display: flex;
            align-items: center;
            gap: 4px;

            .element_info {
              line-height: normal;
            }
          }
        }

        .value_with_image {
          display: flex;
          flex-direction: row;
          gap: 6px;

          .about_trade_icon {
            width: 24px;
            height: 24px;
          }

          .currency_name_field {
            display: flex;
            align-items: center;
            gap: 6px;

            .currency_line {
              width: 1px;
              height: 12px;
              background-color: $dark_palette_6;
            }
          }
        }
      }
    }
  }

  .chat_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: $dark_palette_2;

    .chat_actions_field {
      display: flex;
      flex-direction: column;
      border-top: 1px solid $dark_palette_6;
      background-color: $dark_palette_3;
      width: 100%;
      box-sizing: border-box;

      .chat_input_field {
        display: flex;
        flex-direction: row;
        width: calc(100% - 32px);
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        margin: 12px 16px;

        .chat_send_upload {
          display: flex;
          flex-direction: row;
          font-size: 0;
          gap: 6px;
          align-self: end;

          .chat_send_upload_button {
            border: none;
            background-color: transparent;
            cursor: pointer;

            .action_icon {
              width: 40px;
              height: 40px;
            }
          }
        }

        .chat_input {
          width: calc(100% - 86px);
          box-sizing: border-box;

          .chat_input {
            background-color: transparent;
            border: none;
            color: $white;
            font-size: 14px;
            line-height: 22px;
            outline: none;
            resize: none;

            &::-webkit-scrollbar {
              width: 4px;
              background-color: $dark_palette_4;
            }

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: $dark_palette_4;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $dark_palette_6;
              border-radius: 2px;
            }
          }

          .upload_file_drag {
            display: none;
          }

          .uploaded_files {
            width: calc(100% - 32px);
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
}

.chat_actions {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0;
  border-top: 1px solid $dark_palette_5;

  .arbitrage_field {
    display: flex;
    align-items: center;
    gap: 6px;

    .arbitrage_icon {
      width: 24px;
      height: 24px;
    }

    .arbitrage_icon_disabled {
      display: block;
    }

    .arbitrage_icon_hovered {
      width: 24px;
      height: 24px;
      display: none;
    }

    &:hover {
      .arbitrage_icon_disabled {
        display: none;
      }

      .arbitrage_icon_hovered {
        display: block;
      }
    }

    .arbitrage {
      color: $white;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:not(:disabled) {
        &:hover {
          color: $primary-green;
        }
      }

      &:disabled {
        cursor: default;
      }
    }
  }

  .disable_arbitrage_field {
    opacity: 0.5;
  }

  .chat_action_buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.chat_popup {
  width: 728px;
  height: 714px;
  max-width: 90%;
  max-height: 90%;
  padding: 24px;
  box-sizing: border-box;
  background-color: $dark_palette_1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;

  .chat_popup_header {
    display: flex;
    flex-direction: column;

    .close_icon {
      width: 20px;
      height: 20px;
      align-self: end;
      cursor: pointer;
    }

    .trade_details_title {
      color: $white;
      font-size: 24px;
      font-family: boldFont;
      line-height: 32px;
      align-self: center;
    }
  }

  .chat_information {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .chat_messages_section {
    height: 100%;
  }

  .chat_section {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .chat_messages_section {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .chat_messages {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
