.profile_cards_container {
  background-color: $dark_palette_2;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  min-height: 500px;

  .empty_cards_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .empty_cards_content {
      display: flex;
      flex-direction: column;
      gap: 22px;
      align-items: center;

      .no_card_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .no_cards_icon {
          width: 42px;
          height: 42px;
        }

        .no_card_info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .no_card_title {
            font-size: 20px;
            line-height: 28px;
            color: $white;
            font-family: mediumFont;
          }

          .no_card_description {
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .cards_section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .cards_grid {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 500px;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.profile_cards_container_empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single_card_section {
  background-color: $dark_palette_3;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card_info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 24px);
    box-sizing: border-box;

    .card_icon {
      width: 70px;
      height: 24px;
    }

    .card_data {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: calc(100% - 80px);
      box-sizing: border-box;

      .card_text {
        color: $placeholder_color;
        font-size: 16px;
        line-height: 24px;
        width: calc(100% - 10px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .delete_icon_field {
    font-size: 0;

    .delete_icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: block;
    }

    .delete_icon_active {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: none;
    }

    &:hover {
      .delete_icon {
        display: none;
      }

      .delete_icon_active {
        display: block;
      }
    }
  }

  .check_icon_field {
    font-size: 0;

    .check_icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}

.trade_modal_cards {
  padding: 10px 16px;
  cursor: pointer;
}
