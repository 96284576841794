.balance_section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  background: rgb(0, 180, 180);
  background: radial-gradient(
    circle,
    rgba(0, 180, 180, 1) 0%,
    rgba(0, 145, 145, 1) 0%,
    rgba(0, 214, 214, 1) 0%,
    rgba(0, 152, 152, 1) 0%,
    rgba(0, 100, 100, 1) 0%,
    rgba(0, 66, 66, 1) 100%,
    rgba(0, 47, 47, 1) 100%,
    rgba(0, 26, 26, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );

  .balance_header {
    display: flex;
    align-items: center;
    gap: 6px;

    .balance_icon {
      width: 24px;
      height: 24px;
    }

    .balance_title {
      font-family: mediumFont;
      font-size: 16px;
      line-height: 24px;
      color: $white;
    }

    .show_hide_icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .user_balance {
    position: relative;

    .balance {
      color: $white;
      font-size: 20px;
      font-family: mediumFont;
      line-height: 28px;
    }

    .hide_balance {
      filter: blur(0.5rem);
    }
  }
}

.profile_menu_list {
  .profile_list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;

    .list_element {
      padding: 10px;
      border-radius: 4px;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: $dark_palette_2;
        transition: all 0.3s;
      }

      .element_name {
        color: $placeholder_color;
        font-size: 16px;
        line-height: 24px;
      }

      .active_element_name {
        color: $primary_green;
      }
    }

    .list_element_line {
      width: 100%;
      height: 0.5px;
      background-color: $dark_palette_6;
    }
  }
}
