.profile_account {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;

  .account_element_field {
    background-color: $dark_palette_2;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .user_form {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .inputs_field {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      @include phone {
        grid-template-columns: repeat(1, 1fr);
      }

      .image_input {
        grid-column: span 1;
      }

      .input_wrapper {
        grid-column: span 1;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  .upload_passport_image {
    display: none;
  }

  .user_action {
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $dark_palette_2;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include phone {
      flex-direction: column;
      gap: 12px;
      padding: 16px;
    }

    .user_action_left_side {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;

      @include phone {
        flex-wrap: wrap;
      }

      .action_image_field {
        width: fit-content;
        height: fit-content;
        font-size: 0;

        .user_action_icon {
          width: 24px;
          height: 24px;
        }
      }

      .user_action_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .user_action_name {
          font-family: mediumFont;
          color: $white;
          line-height: 24px;
          font-size: 16px;

          @include phone {
            white-space: nowrap;
          }
        }
      }

      .two_factor_label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        font-size: 0;

        @include phone {
          gap: 2px;
        }

        .checked_icon {
          @include phone {
            width: 20px;
            height: 20px;
          }
        }

        .two_factor_state {
          font-size: 16px;
          font-family: mediumFont;
          line-height: 24px;

          @include phone {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .activated {
          color: $system_green;
        }

        .inactivated {
          color: $system_red;
        }
      }
    }
  }

  .disable_user_action {
    opacity: 0.5;
  }

  .cursor_mouse {
    cursor: default;
  }
}
